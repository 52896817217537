import { Download } from "@mui/icons-material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { VpnProtocol } from "./consts";

const openvpn = {
  color: "openvpn",
  title: VpnProtocol.OpenVPN,
  clientUrl: "https://openvpn.net/client/",
};

const outline = {
  color: "outline",
  title: VpnProtocol.Outline,
  clientUrl: "https://getoutline.org/get-started/#step-3",
};

const config = {
  [VpnProtocol.OpenVPN]: openvpn,
  [VpnProtocol.Outline]: outline,
};

export function AppDownloadButton(props = { protocol: VpnProtocol.OpenVPN }) {
  const { protocol } = props;
  const { color, title, clientUrl } = config[protocol];
  return (
    <Button
      size="medium"
      variant="contained"
      fullWidth
      color={color}
      startIcon={<Download />}
      href={clientUrl}
      target="_blank"
    >
      {title}
    </Button>
  );
}

AppDownloadButton.propTypes = {
  protocol: PropTypes.string,
};

AppDownloadButton.defaultProps = {
  protocol: VpnProtocol.OpenVPN,
};
