import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { AppDownloadButton } from "./AppDownloadButton";
import { VPN_STATUS_ACTIVE, VpnProtocol } from "./consts";

import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import ReactCountryFlag from "react-country-flag";

export function AppDownloadCard(props = { token: null, apiUrl: null }) {
  const { token, apiUrl } = props;
  const [{ protocol, data, httpStatus, key, status, host, country }, setState] =
    useState({
      key: null,
      status: null,
      host: null,
      country: null,
      protocol: null,
      data: null,
      httpStatus: null,
    });

  useEffect(() => {
    if (token && apiUrl) {
      fetch(`${apiUrl}/client/download`, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => Promise.all([res.status, res.json()]))
        .then(([status, data]) => setState({ httpStatus: status, ...data }));
    }
  }, [apiUrl, token]);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          EarlGreyVPN
        </Typography>
        <Divider className="divider" />
        <Typography variant="body1" color="text.primary" align="left">
          Hey! You are almost there
        </Typography>
        <Typography variant="body1" color="text.primary" align="left">
          We're getting ready to launch our brand new mobile client
        </Typography>
        <Typography variant="body1" color="text.primary" align="left">
          Meanwhile you can use the free open source client!
        </Typography>
        {httpStatus && httpStatus !== 200 && (
          <React.Fragment>
            <br />
            <Divider className="divider" />
            <Typography variant="body1" color="error" align="left">
              Something went wrong. Please try again later
            </Typography>
          </React.Fragment>
        )}
        {httpStatus === 200 && protocol === VpnProtocol.Outline && data && (
          <React.Fragment>
            <Divider className="divider" />

            <Typography className="outlineCertUrl">
              <a href={data} rel="noopener noreferrer">
                {data}
              </a>
            </Typography>
          </React.Fragment>
        )}
        {protocol && httpStatus === 200 && status === VPN_STATUS_ACTIVE && (
          <React.Fragment>
            <Divider className="divider" />
            <Typography
              variant="body2"
              color="text.primary"
              align="left"
              className="divider"
            >
              {key} {status === "active" ? "🟢" : "⚪"} | {host}{" "}
              {<ReactCountryFlag countryCode={country} svg className="countryFlag"/>}
            </Typography>
          </React.Fragment>
        )}
      </CardContent>
      {protocol && status === VPN_STATUS_ACTIVE && (
        <CardActions>
          <AppDownloadButton protocol={protocol} />
        </CardActions>
      )}
    </Card>
  );
}

AppDownloadCard.propTypes = {
  token: PropTypes.string,
  apiUrl: PropTypes.string,
};

AppDownloadCard.defaultProps = {
  token: null,
  apiUrl: null,
};
