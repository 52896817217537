import { Button, CardActions, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { Telegram } from "@mui/icons-material";

export function WelcomeCard(props = { botUsername: null }) {
  const { botUsername } = props;

  let supportsTGLinks = false;
  const browserInfo = navigator.userAgentData;
  const brands =
    browserInfo?.brands?.filter((brandData) => brandData?.brand) ?? [];
  if (brands.length > 0) {
    supportsTGLinks =
      browserInfo.brands.some((b) => b.brand === "Chromium") &&
      !browserInfo.brands.some(
        (b) => b.brand.includes("Apple") || b.brand.includes("Safari"),
      );
  }

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Welcome to EarlGreyVPN
        </Typography>
        <Divider className="divider" />
        <Typography variant="body1" color="text.primary" align="left">
          Hey! Thanks for choosing us
        </Typography>
        <Typography variant="body1" color="text.primary" align="left">
          Just get a key via bot {!supportsTGLinks && ` @${botUsername} `}
          and you're good to go
        </Typography>
      </CardContent>
      {supportsTGLinks && (
        <CardActions>
          <Button
            size="medium"
            variant="outlined"
            fullWidth
            startIcon={<Telegram />}
            href={`https://t.me/${botUsername}`}
            target="_blank"
          >
            @{botUsername}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

WelcomeCard.propTypes = {
  botUsername: PropTypes.string,
};

WelcomeCard.defaultProps = {
  botUsername: null,
};
