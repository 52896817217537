import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";

import logo from "./logo.png";
import "./App.css";
import { AppDownloadCard } from "./AppDownloadCard";
import { WelcomeCard } from "./WelcomeCard";

const theme = createTheme({
  palette: {
    openvpn: {
      main: "#ffa54a",
      contrastText: "#fff",
    },
    outline: {
      main: "#183729",
      contrastText: "#fff",
    },
  },
});

function App() {
  const token = new URL(window.location.href).searchParams.get("token");
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <img src={logo} className="App-logo" alt="logo" />
        {token && apiUrl && <AppDownloadCard token={token} apiUrl={apiUrl} />}
        {!token && (
          <WelcomeCard botUsername={process.env.REACT_APP_BOT_USERNAME} />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
